import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';
import { map, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class WebapiService {
  serviceUrl = environment.serviceUrl;
  ocrUrl=environment.ocrUrl
  public saveProduces: any = undefined;
  constructor(
    public Http: HttpClient,
    public CF: CommonService,
  ) {

  }

  public Token(): Promise<any> {

    try {
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/Token`;
        // const body = {
        //   // "username": "ezee_sympl",
        //   // "password": "ezee_sympl159"
        //   // "username": "ezee_admin",
        //   // "password": "ezee_admin123"
        //   "username": "Y7#n$Pn",
        //   "password": "salama_test"


        // }
        const body = {
          "username": "Sympl",
          "password": "Sympl159"
        }
        return this.Http.post<any>(url, body)
          .pipe(
            map(d => (d.code === 1) ? d.document.accessToken : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }

  public OCR_Token(): Promise<any> {

    try {
      return new Promise((resolve, reject) => {
        const url = this.ocrUrl+":"+environment.authPort+"/auth";
        const body = {
          "username": "Symplfy",
          "password": "HNUJP10XoVcA8CoLDGi4"
        }
        return this.Http.post<any>(url, body)
          .pipe(
            map(d => (d) ? d.access_token : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }

  public Login(data: any) {
    const url = `${this.serviceUrl}/Clients/filter?andOr=AND&page=1&itemsPerPage=100`;
    const body = [{
      "columnName": "client_email",
      "columnValue": data.Email
    },
    {
      "columnName": "client_password",
      "columnValue": data.Password
    }];

    return this.Http.post<any>(url, body).pipe(
      map(d => (d.code === 1) ? d.document.records : 'failed'))

  }



  public img_upload(data): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        const url = this.ocrUrl+":"+environment.rcPort+"/output_ocr";
        // const url = "https://video.bizilliant.com:9020/output_ocr";
        this.Http.post(url, data).pipe(
        ).subscribe((r: any) => resolve(r), reject);
      });
    } catch (error) {
      console.log(error);
    }
  }

  public Odoimg_upload(data): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        const url = "https://ocrai.symplfy.in:9020/output_odom";
        this.Http.post(url, data).pipe(
        ).subscribe((r: any) => resolve(r), reject);
      });
    } catch (error) {
      console.log(error);
    }
  }

  public Engine_image_upload(data): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        const url = "https://ocrai.symplfy.in:9020/output_veh_pn";
        this.Http.post(url, data).pipe(
        ).subscribe((r: any) => resolve(r), reject);
      });
    } catch (error) {
      console.log(error);
    }
  }

  public Chassis_image_upload(data): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        const url = "https://ocrai.symplfy.in:9020/output_chassis";
        this.Http.post(url, data).pipe(
        ).subscribe((r: any) => resolve(r), reject);
      });
    } catch (error) {
      console.log(error);
    }
  }




  public upload(formData: any) {
    return this.Http.post<any>(
      "https://devtoiapi.symplfy.in/v1/api/Upload/upload",
      formData,
      { reportProgress: true, observe: "events" }
    );
  }

}
