import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './shared/auth.guard';
import { BodyComponent } from './main/body/body.component';
import { ErrorComponent } from './main/error/error.component';
import { UnauthorizedComponent } from './main/unauthorized/unauthorized.component';

const routes: Routes = [

  // {
  //   path: '',
  //   loadChildren: () => import('./main/authentication/authentication.module').then((m) => m.AuthenticationModule),
  // },
  // {
  //   path: '',
  //   component: BodyComponent,
  //   // canActivate: [AuthGuard],
  //   // canActivateChild: [AuthGuard],
  //   children: [
  //     { path: 'dashboard', loadChildren: () => import('./main/app/dashboard/dashboard.module').then(m => m.DashboardModule) },
  //     { path: 'myprofile', loadChildren: () => import('./main/app/myprofile/myprofile.module').then(m => m.MyprofileModule) },
  //   ]
  // },
  {
    path: '',
    component: BodyComponent,
    // canActivate: [AuthGuard],
    // canActivateChild: [AuthGuard],
    children: [
      { path: 'newinspection', loadChildren: () => import('./main/app/newinspection/newinspection.module').then(m => m.NewinspectionModule) },

      { path: 'uploadcarimage', loadChildren: () => import('./main/app/uploadcarimage/uploadcarimage.module').then(m => m.UploadcarimageModule) },

      { path: 'termsandconditions', loadChildren: () => import('./main/app/termsandconditions/termsandconditions.module').then(m => m.TermsandconditionsModule) },

      { path: 'rcupload', loadChildren: () => import('./main/app/recupload/recupload.module').then(m => m.RecuploadModule) },

      { path: 'enginenumber', loadChildren: () => import('./main/app/engineno/engineno.module').then(m => m.EnginenoModule) },

      { path: 'thankyou', loadChildren: () => import('./main/app/thankyou/thankyou.module').then(m => m.ThankyouModule) },

      { path: 'instructions', loadChildren: () => import('./main/app/instructions/instructions.module').then(m => m.InstructionsModule) },
      { path: 'jobcreate', loadChildren: () => import('./main/app/createjob/createjob.module').then(m => m.CreatejobModule) },
      { path: 'linkcheck', loadChildren: () => import('./main/app/linkcheck/linkcheck.module').then(m => m.LinkcheckModule) },

    ]
  },
  { path: 'error', component: ErrorComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },






  { path: '**', redirectTo: '/error' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
