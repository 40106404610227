<div id="app-container" [className]="sidebar.containerClassnames">
  <app-topnav></app-topnav>
  <!-- <main>
    <div class="container-fluid"> -->
      <router-outlet></router-outlet>
    <!-- </div>
  </main> -->
  <app-footer></app-footer>
</div>

<!-- <div>Hai</div> -->
<!-- <div id="app-container" [className]="sidebar.containerClassnames"> -->
  <!-- <app-topnav></app-topnav>
  <app-sidebar></app-sidebar>
  <main>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
            <app-heading></app-heading>
            <app-breadcrumb></app-breadcrumb>
            <div class="separator mb-3"></div>
        </div>
    </div>
      <router-outlet></router-outlet>
    </div>
  </main> -->
  <!-- <main> -->
    <!-- <div class="container-fluid">
      
    </div> -->
    <!-- <router-outlet></router-outlet>
  </main>
  <app-footer></app-footer>
</div> -->

